<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="文件名称">
              <a-input v-model="queryParam.name" @pressEnter="getDataList" placeholder="请输入文件名称"/>
            </a-form-item>
          </a-col>
          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="getDataList">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetForm">重置</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" @click="$refs['action_form'].add(currentId)" icon="plus">上传文件</a-button>
    </div>

    <a-list
      class="demo-loadmore-list"
      :loading="loading"
      item-layout="horizontal"
      :data-source="dataList"
      :pagination="pagination"
    >
      <a-list-item slot="renderItem" slot-scope="item, index">
        <img v-if="type(item.suffix) == 'img'"
             width="50"
             alt="logo"
             style="margin-right: 10px; cursor: pointer"
             :src="getFileUrl(item.url)"
             @click="handlePreview(item, false)"
        />
        <div v-else style="width: 50px; text-align: center; cursor: pointer; margin-right: 10px" @click="handlePreview(item, false)">
          <a-icon style="font-size: 40px;" :type="type(item.suffix)"/>
        </div>
        <a slot="actions" @click="cut(item)">移动</a>
        <a slot="actions" @click="copy(item)">复制</a>
        <a slot="actions" @click="rename(item)">重命名</a>
        <a slot="actions">
          <a-popconfirm placement="topRight" title="确认删除？" okText="确认" cancelText="取消" @confirm.stop="() => del(item)">
            <span>删除</span>
          </a-popconfirm>
        </a>
        <a-list-item-meta :description="item.createTime">
          <a slot="title" :href="getFileUrl(item.url)" style="cursor: pointer">{{ item.name }}</a>
        </a-list-item-meta>

      </a-list-item>
    </a-list>

    <source-file-upload-modal ref="action_form" @ok="handleOk"/>
    <source-file-copy-or-cut-modal ref="file_copy_or_cut" @ok="handleOk"/>
    <source-file-rename ref="file_rename" @ok="handleOk"/>

    <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = !previewVisible">
      <img alt="example" style="width: 100%" :src="getFileUrl(previewImage)" />
    </a-modal>
  </a-card>
</template>

<script>
import {list, delDirOrFile, addFile} from '@/api/system/source'
import SourceFileUploadModal from './SourceFileUploadModal'
import SourceFileCopyOrCutModal from "@/views/system/source/components/SourceFileCopyOrCutModal";
import SourceFileRename from "@/views/system/source/components/SourceFileRename";

export default {
  name: 'SourceFileList',
  components: {
    SourceFileRename,
    SourceFileCopyOrCutModal,
    SourceFileUploadModal
  },
  props: {
    currentId: {
      type: String,
      default() {
        return ''
      }
    }
  },
  data() {
    return {
      previewVisible: false,
      previewImage: '',
      loading: false,
      advanced: false,
      queryParam: {
        parentId: 0,
        type: 2,
        name: '',
        pageNum: 1,
        pageSize: 8,
      },
      dataList: [],
      total: 0,
      pagination: {
        onChange: pageNum => {
          this.queryParam.pageNum = pageNum
          this.getDataList()
        },
        pageSize: 8,
        total: 0
      },
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.loading = true
      this.queryParam.parentId = this.currentId
      list(this.queryParam).then(res => {
        if (res.code === 200) {
          this.dataList = res.rows
          this.pagination.total = res.total
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleOk() {
      this.getDataList()
    },

    refresh() {
      this.getDataList()
    },

    resetForm() {
      this.queryParam = {
        parentId: 0,
        type: 2,
        name: '',
        pageNum: 1,
        pageSize: 8,
      }
      this.pagination.current = 1
      this.getDataList()
    },

    cut(item) {
      this.$refs['file_copy_or_cut'].init(item, false)
    },
    copy(item) {
      this.$refs['file_copy_or_cut'].init(item, true)
    },
    rename(item) {
      this.$refs['file_rename'].init(item)
    },
    del(item) {
      this.loading = true;
      delDirOrFile(item.id).then(res =>{
        if (res.code === 200) {
          this.$notification.success({message: '删除成功'})
          this.getDataList()
        }
      }).finally(()=> {
        this.loading = false;
      })
    },

    type(suffix) {
      if (['jpg', 'jpeg', 'png', 'gif'].includes(suffix)) {
        return 'img';
      }
      switch (suffix) {
        case 'ppt':
        case 'pptx':
          return 'file-ppt'
        case 'xls':
        case 'xlsx':
          return 'file-excel'
        case 'doc':
        case 'docx':
          return 'file-word'
        case 'zip':
        case 'rar':
          return 'file-zip'
        case 'json':
        case 'text':
          return 'file-text'
        case 'pdf':
          return 'file-pdf'
        default:
          return 'file-unknown'
      }
    },
    getFileUrl(src) {
      return process.env.VUE_APP_API_FILE_URL + src
    },
    async handlePreview(item, isName) {
      if (!isName && this.type(item.suffix) == 'img') {
        this.previewImage = item.url
        this.previewVisible = true
        return
      }
      let link = document.createElement('a');
      link.href = item.url;
      link.click();
    },

  }
}
</script>

<style lang="less">
.table-operator {
  margin-bottom: 2px;
}
button {
  margin-right: 8px;
}
</style>
